/**
 * FooterSimple produces a footer containing a logo, a description, and
 * a set of links.
 * Supported props: src localizerfn
 */
import React from 'react';
import LinkMenu from './LinkMenu';
import { Link } from 'gatsby';
import { css }from '../util/pagetools';
import logo from '../images/logo_footer.png';

export default function Footer(props) {
  const cfg = props.src;
  const footlinks = (cfg.toplinks ? cfg.toplinks.concat(cfg.links) : cfg.links);
  const localized = props.localizerfn;

  return (
    <footer className={css('footer', cfg.theme)}>
      <div id="footer-logo">
        <Link to={localized('/')}>
          <img src={logo} alt={cfg.sitename} className="img-responsive" />
        </Link>
      </div>
      <div className="flx-v-sm">
        <div className="footer-links fb-thirds-1">
          <LinkMenu links={footlinks} itemStyle="nav-item vertical text-white" localizerfn={localized} />
        </div>
        <div className="footer-desc text-white">{cfg.text}</div>
      </div>
    </footer>
  );
};
